import React from "react"
import { useSiteMetadata } from "./use-site-metadata"

export const SeoC = ({ title, description, image, pathname, children }) => {
  const { title: defaultTitle, description: defaultDescription, seoImage, siteUrl } = useSiteMetadata()


  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || `${siteUrl}${seoImage}`,
    url: `${siteUrl}${pathname || ``}`
  }


  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="format-detection" content="telephone=no" />
      
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.seoImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      {children}
    </>
  )
}