import * as React from "react"
import { SeoC } from "../../components/utils/seo"
import './df.scss'

const SSRPage = ({ serverData }) => (
  <div className="df">
    
    <div className="df--bx">
      <p>{serverData && serverData.data[0].attributes.body}</p>
    </div>
    {/* <span className="brand">1001001</span> */}
  </div>
)

export default SSRPage

export const Head = () => (
  <SeoC title="🐶" description="One random dog fact at a time!" />
)

export async function getServerData() {
  try {
    const res = await fetch(`https://dogapi.dog/api/v2/facts`,{
      headers: {
        'Accept': 'application/json',
      }
    })

    if (!res.ok) {
      throw new Error(`Response failed`)
    }

    return {
      props: await res.json(),
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {}
    }
  }
}